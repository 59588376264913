import React from 'react';
import {View, StyleSheet, Text ,Image, } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableHeaderNewForGroup from './InvoiceTableHeaderNewForGroup';
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableRowNewForGroup from "./InvoiceTableRowNewForGroup"
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'


const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        // marginTop: 30,
    //    marginBottom:20,
    
        width:"380em",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View >
    {/* yhan pe 30 ki jga 5 kiya 19-jan */}
    {/* 23-jan 5 ki jga 0 kr dya*/}
    {/* invoice.flight_no!=""?4 9-feb-2023 for covid report 3101424 */}
    {/* MarginTop by default 0 tha -10 kr dya thora oper aa gya hai abi ni kiya */}
    {/* invoice.Panel_Name=="PMAS-AAUR"?25 */}

   <View style={[{
     marginTop:invoice.cnic!=""&&invoice.father_husband=="null"&&invoice.if_panelMR_cnic_father==true ? 10:invoice.father_husband!="null"&&invoice.cnic==""&&invoice.if_panelMR_cnic_father==true? 10:invoice.father_husband!="null" &&invoice.cnic!=""&&invoice.if_panelMR_cnic_father==true ?20:invoice.flight_no!=""?4:invoice.invoice_id==4102260?-10:0,},styles.tableContainer]}>
   {/* 5-may-2024 */}
   {/* 7-may-2024 invoice.reports[0].test_title.includes("USG Guided FNA Cytology") add this one abi 3 he hn USG 3no pe ker di hai  */}
   {/* ?. ye lgae thy 7-may-2023 ko ku k prescription ka pdf open ni ho rha tha whan error aa rha tha testTile undefind hai  */}
   {invoice.reports[0]?.test_title.includes("USG Guided Trucut Biopsy")||invoice.reports[0]?.test_title.includes("USG Guided FNA Cytology")||invoice.reports[0]?.test_title.includes("USG Guided Diagnostic Tap Procedure")?
// console.log(invoice.reports[0].test_title)
<InvoiceTableHeaderNewForGroup />


:
  <InvoiceTableHeader /> 

}
  
{/* {invoice.reports[0].test_title.includes("USG Guided Trucut Biopsy")? */}
   {/* 7-may-2024 invoice.reports[0].test_title.includes("USG Guided FNA Cytology") add this one abi 3 he hn USG 3no pe ker di hai   */}
{invoice.reports[0]?.test_title.includes("USG Guided Trucut Biopsy")||invoice.reports[0]?.test_title.includes("USG Guided FNA Cytology")||invoice.reports[0]?.test_title.includes("USG Guided Diagnostic Tap Procedure")?

   <InvoiceTableRowNewForGroup items={invoice.reports} key={invoice.name} invoice={invoice} />
:
    <InvoiceTableRow items={invoice.reports} key={invoice.name} invoice={invoice} /> 

}

   {/* 23-jan marginTop dya hai >9 9401464 hai >13 7001413 k lye lgae thi baqiyon k lye Invoice me 275 line pe lgae thi */}
   {/* ye specific report k lye aik cond thi 8401429 */}
   <View style={{marginTop:invoice.invoice_id=="0339962"?35:invoice.reports.length>9&&invoice.reports.length<13?20:invoice.reports.length>13?40: 0}}>
        
        <View style={ {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold',
        // backgroundColor:"green"
        }} key={"q123"}>
              <Text style={{ 

width: '50%',
textAlign: 'left',
borderColor: 'black',
borderWidth: 0.5,
height:'100%',

fontSize:6.5,
padding: 5,

}}>
    {invoice.Invoice_Remarks}
</Text>
<Text style={{ 

width: '5%',
textAlign: 'left',
height:'100%',

fontSize:6.5,
padding: 5,

}}>
  

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Net Cost"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Value+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"2w1"}>
              <View style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:5,
 flexDirection: 'column',



}}>
    {/* 21-jan-23 */}
                            <Text style={{ fontSize: 7,}}>
                                User : {invoice.User_Name}
                            </Text>
                            <Text style={{ fontSize: 7, }}>
                            Date/Time : {invoice.invoice_date} {invoice.invoice_time} Hrs
                            </Text>
                          
                          

</View>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Discount"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Discount_Total+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold',
        // backgroundColor:"green"
        }} key={"221"}>
              <View style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
paddingTop:-10,
marginTop:5,
fontWeight:"bold"
}}>
    {/* 21-jan-23  */}
                            {/* 25-nov-2024 */}

      <Text style={{ fontSize: 7 ,marginTop:5, fontFamily: 'Helvetica-Bold',}}>
      Note : 
                            </Text>
                

</View>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Invoice Net Payable"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Payable+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold',
        // backgroundColor:"grey"
        }} key={"221"}>
              <View style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
// marginTop:10,
color:"black",
paddingTop:-10,
flexDirection:"column",
// backgroundColor:"grey"
}}>
    {/* {
    invoice.flight_no!="" ?
                 
      <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
   fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
   {"I have read and verified my particulars as correct"}
   </Text>
  
               :
               null
    } */}
    {/* 25-nov-2024 */}
    <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
//    fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
 o Reporting time is subject to the sample submission. 
   </Text>
   <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
//    fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
 o Please bring this from to collect the reports.
   </Text>
   <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
//    fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
 o In case of any query regarding report, please call at the given 
   </Text>
   <Text style={{ 
   
   textAlign: 'left',
   padding:2, 
//    fontFamily: 'Helvetica-Bold',
   fontSize:6.5,
   color:'black'
   }}>
  number within 48 hours.
   </Text>


</View>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Total Received Value"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Received+" Rs"}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
color:"black",
paddingTop:-10
}}>
      {/* {
    invoice.flight_no!="" ?
                 
    <Text style={{ 
           
        textAlign: 'left',
        padding:2, 
        fontSize:6.5,
        color:'black'
        }}>
        {"Name : "+invoice.name}
        </Text>
  
               :
               null
    } */}


</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Balance Value"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{invoice.Invoice_Balance+" Rs"}</Text>

            </View>
{/* // 28-june-2024 add a new row mode of payment  */}
          
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:21,
        fontSize:7,
        fontStyle: 'bold',marginTop:0.15
        }} key={"221"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
paddingTop:-10
}}>
    {/* 21-jan-23  */}
      {/* <Text style={{ fontSize: 7 }}>
                            Date/Time : {invoice.invoice_date} {invoice.invoice_time} Hrs
                            </Text> */}
                           

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Mode Of Payment"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 0.5,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5,
                 color:"black"
                 
                 }}>{invoice.Received_mode}</Text>
                 {console.log("check reciebved mode")}
                 {console.log(invoice.Received_mode)}


            </View>
            {/* yhan tak */}
            </View>
           
            
            
</View>
{
    invoice.flight_no!="" ?
    <View style={{
        marginLeft:'2em'
    }}> 
  
      
               <View>
              
                    
             
           <Text style={{ 
           
           textAlign: 'left',
           padding:2, 
           fontSize:6.5,
           color:'black'
           }}>
           {"CNIC : "+invoice.cnic}
           </Text>
           {
               invoice.flight_no!="" ?
               <Text style={{ 
           
                   textAlign: 'left',
                   padding:2, 
                   fontSize:6.5,
                   color:'black'
                   }}>
                   {"Passport No : "+invoice.passport_no}
                   </Text>
                   : null
           }
      
           
          
                       </View>
        <View style={{
            height:30
        }} >
              
                     <Text style={{ 
                             
                    width: '40%',
                    textAlign: 'left',
                    borderColor: 'black',
                    borderWidth: 0.5,
                    fontSize:6.5,
                    padding: 7,
                    
                    
                     }}>{"Signature"}</Text>
                       
      {/* <Text style={{ 
   
   width: '60%',
   textAlign: 'left',
  
   padding:5,  
   fontSize:7,
   }}>
   
   </Text> */}
   
               </View>
               <View style={{paddingLeft:10, paddingTop:1}}>
     <Text style={{
        fontFamily: 'Helvetica-Bold'
     }}>Note:</Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>Reporting time is subject to the sample submission.</Text></Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>Please bring this form to collect the reports.</Text></Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>In case of any query regarding report, please call at the given number within 48 hours.</Text></Text>
     </View>
               </View>
     :
     null
}
 {/* 21-jan-23 */}
    {/* invoice.flight_no!=""? 9-feb-2023 for covid report 3101424 agr covid hai to top:250 pe fix ho jae POs */}

 {invoice.flight_no!=""?
 <View style={{ position: 'absolute', width: '100%', bottom: 0, flexDirection:'column',top:250}}>

           {
                    invoice.pos!=""
                    ?
                    <View
                    style={{
                     display:"flex",
                     flexDirection:"column",
                     marginLeft: '5em', 
                     marginTop:'5em'
                     
                    }}
                 >
             <View
                    style={{
                     display:"flex",
                     flexDirection:"row",
                    
                    }}
                 >
                   <Image style={{
 
 width: 42,
 height: 42,
 }} src={invoice.pos} />
                       <Image style={{
 
                         width: 42,
                         height: 42,
                         }} src={ require("../pos.png")} />
                       
                     </View>
 
                     <Text style={{
                            fontFamily: 'Helvetica-Bold',
                                     fontSize: 8,
                                 }}>{invoice.pos_id}</Text>
                                  </View>
                                  :
                                  null

                
                }
            </View>:<View style={{ position: 'absolute', width: '100%',
// 28-june-2024 add a new row mode of payment 
            //  bottom: 0,
             bottom: 18,
              flexDirection:'column',}}>

{
         invoice.pos!=""
         ?
         <View
         style={{
          display:"flex",
          flexDirection:"column",
          marginLeft: '5em', 
          marginTop:'5em',
        //   backgroundColor:"RED"
          
         }}
      >
  <View
         style={{
          display:"flex",
          flexDirection:"row",
         
         }}
      >
        <Image style={{

width: 42,
height: 42,
}} src={invoice.pos} />
            <Image style={{

              width: 42,
              height: 42,
              }} src={ require("../pos.png")} />
            
          </View>

          <Text style={{
                 fontFamily: 'Helvetica-Bold',
                          fontSize: 8,
                      }}>{invoice.pos_id}</Text>
                       </View>
                       :
                       null

     
     }
 </View> }
</View>
 
  
  );
  
  export default InvoiceItemsTable